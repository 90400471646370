import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  storeName: {
    textAlign: "center",
    textTransform: "uppercase"
  },
  storeTime: {
    textAlign: "center",
    textTransform: "uppercase",
    fontSize: "1rem"
  },
  logo: {
    maxHeight: "100%",
    width: "70%",
    marginLeft: "15%",
    marginRight: "15%",
    marginBottom: "20px"
  }
});
const Header = props => {
    const classes = useStyles();
    const [ time, setTime] = useState(new Date())

    useEffect(() => {
      let TimeId = setInterval(() => setTime(new Date()), 1000);
      return () => {
       clearInterval(TimeId);
      };
    });
    
    return (
        <>
          <a href="/" target="_self"><img src="/static/images/teeam-logo.png" alt="Teeam.io" className={classes.logo} /></a>
          <Typography variant="h4" component="div" className={classes.storeName}>Gisborne FoodWorks</Typography>
          <Typography variant="overline" component="div" className={classes.storeTime}>{time.toLocaleString("en-AU", {timeZone: "Australia/Melbourne"})}</Typography>
        </>
    );
}

export default Header;
