import React from 'react';
import ReactDOM from 'react-dom';
import "@fontsource/roboto";
import './index.css';
import App from './App';

ReactDOM.render(
  <>
    <App />
  </>,
  document.getElementById('teeam-timeclock-app')
);
