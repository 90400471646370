import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Fade from '@material-ui/core/Fade'
import List from '@material-ui/core/List'
import CircularProgress from '@material-ui/core/CircularProgress'
import UserList from './UserList'

const ChangeStatus = props => {
    const [users, setUsers] = useState([])
    const [isLoadingUsers, setIsLoadingUsers] = useState(true)
    useEffect(() => {
        const fetchData = async () => {
            const result = await axios.post("https://williamsonsfoodworks.com.au/api/get_employees_timeclock.php",{store:"2997"}
            ).then(result=>{
                return result
            }).catch(error=>{
                return false
            })
            if(result){
                if(result.data.error === false){
                    setUsers(result.data.employees);
                }
            }
            setIsLoadingUsers(false)
        };
    
        fetchData();
      }, []);

    if(isLoadingUsers){
        return(<Fade in style={{textAlign:"center"}}>
            <div><CircularProgress /></div>
        </Fade>);
    }

    if(users.length === 0){
        return(<Fade in style={{textAlign:"center"}}>
            <div>An error occured loading the user list. Please try again!</div>
        </Fade>);
    }
    let usersList
    if(!isLoadingUsers && users.length > 0){
        usersList = users.map((user, index)=>{
            return <UserList key={index} user={user} />
        })
    }
    return (
        <List component="nav">
            {usersList}
        </List>
    )
}

export default ChangeStatus