import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import TimerIcon from '@material-ui/icons/Timer';
import TimerOffIcon from '@material-ui/icons/TimerOff';
import UpdateIcon from '@material-ui/icons/Update';
import Forward30Icon from '@material-ui/icons/Forward30';
import ModeContext from './ModeContext';

const useStyles = makeStyles({
  listItem: {
    backgroundColor: "#60489D",
    color: "white",
    "&:hover": {
      backgroundColor: "#60489D !important",
    }
  },
  listIcons: {
    color: "white",
    marginTop: "10px",
    marginRight: "20px"
  },
  listItemText: {
    "&> span": {
      fontWeight: "bold",
      fontSize: "1.5rem"
    }
  },
  divider: {
    marginTop: "5px",
    marginBottom: "5px"
  }
});
const Home = props => {
    const classes = useStyles();
    const {setMode} = useContext(ModeContext)
    return (
          <List component="nav">
            <ListItem button className={classes.listItem} onClick={()=>setMode(0)}>
              <ListItemText
                className={classes.listItemText}
                primary={
                  <Grid
                    spacing={0}
                    container 
                    alignItems="center"
                    justifyContent="center">
                    <Grid
                    item
                    style={{textAlign:"right"}}
                    xs={5}
                  >
                      <TimerIcon fontSize="large" className={classes.listIcons} />
                    </Grid>
                  <Grid
                  item
                  xs={7}
                >
                    START WORK
                  </Grid>
                    </Grid>
                }
              />
            </ListItem>
            <Divider className={classes.divider} />
            <ListItem button className={classes.listItem} onClick={()=>setMode(2)}>
              <ListItemText
                className={classes.listItemText}
                primary={
                  <Grid
                    spacing={0}
                    container 
                    alignItems="center"
                    justifyContent="center">
                    <Grid
                    item
                    style={{textAlign:"right"}}
                    xs={5}
                  >
                      <Forward30Icon fontSize="large" className={classes.listIcons} />
                    </Grid>
                  <Grid
                  item
                  xs={7}
                >
                    START BREAK
                  </Grid>
                    </Grid>
                }
              />
            </ListItem>
            <Divider className={classes.divider} />
            <ListItem button className={classes.listItem} onClick={()=>setMode(3)}>
              <ListItemText
                className={classes.listItemText}
                primary={
                  <Grid
                    spacing={0}
                    container 
                    alignItems="center"
                    justifyContent="center">
                    <Grid
                    item
                    style={{textAlign:"right"}}
                    xs={5}
                  >
                      <UpdateIcon fontSize="large" className={classes.listIcons} />
                    </Grid>
                  <Grid
                  item
                  xs={7}
                >
                    END BREAK
                  </Grid>
                    </Grid>
                }
              />
            </ListItem>
            <Divider className={classes.divider} />
            <ListItem button className={classes.listItem} onClick={()=>setMode(1)}>
              <ListItemText
                className={classes.listItemText}
                primary={
                  <Grid
                    spacing={0}
                    container 
                    alignItems="center"
                    justifyContent="center">
                    <Grid
                    item
                    style={{textAlign:"right"}}
                    xs={5}
                  >
                      <TimerOffIcon fontSize="large" className={classes.listIcons} />
                  </Grid>
                  <Grid
                  item
                  xs={7}
                >
                    END WORK
                  </Grid>
                    </Grid>
                }
              />
            </ListItem>
          </List>
    );
}

export default Home;
